import { cn } from "~/core/styles/helpers";
import { LoadingSpinner } from "~/coreUI/Basic/icons";
import { type UiElement } from "~/core/types";

interface LoadingFeedbackProps extends UiElement {
  title?: string;
  description?: string;
  shapeStyle?: "fullPage" | "inline";
  color?: "primary" | "secondary" | "default";
}

const Content = (props: LoadingFeedbackProps) => {
  return (
    <div className={cn("flex flex-col gap-4")}>
      <div className="colored text-lg font-bold">
        {props.title ?? "Loading..."}
      </div>

      <div className={cn("text-xs font-light", props.description)}>
        {props.description ?? "Loading..."}
      </div>
    </div>
  );
};

export const LoadingFeedback = ({
  shapeStyle = "fullPage",
  color = "primary",
  ...props
}: LoadingFeedbackProps) => {
  return (
    <>
      {shapeStyle === "fullPage" && (
        <div
          className={cn(
            "bg-default absolute left-0 top-0 flex h-screen w-screen flex-col items-center justify-center gap-4",
            color
          )}
        >
          <div className="flex justify-center pb-8">
            <LoadingSpinner color={color} />
          </div>
          <Content {...props} />
        </div>
      )}
      {shapeStyle === "inline" && (
        <div
          className={cn(
            "flex h-full w-full flex-col items-center justify-center gap-8",
            props.className
          )}
        >
          <LoadingSpinner color={color} />
          <Content {...props} />
        </div>
      )}
    </>
  );
};
