import { useRouter } from "next/router";
import { useEffect } from "react";
import { Container } from "~/coreUI/Layout/Container/Container";
import { LoadingFeedback } from "~/coreUI/Feedback/LoadingFeedback/LoadingFeedback";

export default function Custom404() {
  const router = useRouter();

  useEffect(() => {
    void router.replace("/");
  }, []);

  return (
    <Container xPadding="all" yPadding="md">
      <h1 className="text-2xl">404 - Page Not Found</h1>
      <LoadingFeedback description="Redirecting to home page..." />
    </Container>
  );
}
